// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-container[data-v-4b8aa600]{align-items:center;display:flex}.toggle-container span[data-v-4b8aa600]:first-child{padding-right:6px}.toggle-container span[data-v-4b8aa600]:last-child{padding-left:6px}.switch[data-v-4b8aa600]{position:relative;display:inline-block;width:48px;height:24px}.switch input[data-v-4b8aa600]{opacity:0;width:0;height:0}.slider[data-v-4b8aa600]{cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:var(--checkbox-disabled-bg)}.slider[data-v-4b8aa600],.slider[data-v-4b8aa600]:before{position:absolute;transition:.4s}.slider[data-v-4b8aa600]:before{content:"";height:16px;width:16px;left:4px;bottom:4px;background-color:var(--checkbox-tick)}input:checked+.slider[data-v-4b8aa600]{background-color:var(--checkbox-ticked-bg)}input:focus+.slider[data-v-4b8aa600]{box-shadow:0 0 1px var(--checkbox-ticked-bg)}input:checked+.slider[data-v-4b8aa600]:before{transform:translateX(24px)}.slider.round[data-v-4b8aa600]{border-radius:34px}.slider.round[data-v-4b8aa600]:before{border-radius:50%}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
