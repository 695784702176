// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top[data-v-da4d8e56]{display:flex;position:relative}.top>h2[data-v-da4d8e56]{flex:1}.close-button[data-v-da4d8e56]{cursor:pointer;display:flex;align-items:center;justify-content:center;padding:2px}.close-button>i[data-v-da4d8e56]{font-size:14px;opacity:.5}.close-button[data-v-da4d8e56]:hover{background-color:var(--wm-closer-hover-bg)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
