// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tolerations[data-v-73d555e6]{width:100%}.rule[data-v-73d555e6],.toleration-headers[data-v-73d555e6]{display:grid;grid-template-columns:20% 10% 20% 15% 20% 15%;grid-gap:10px;align-items:center}.rule[data-v-73d555e6]{margin-bottom:20px}.rule .col[data-v-73d555e6]{height:100%}.toleration-headers SPAN[data-v-73d555e6]{color:var(--input-label);margin-bottom:10px}.remove BUTTON[data-v-73d555e6]{padding:0}.height-adjust-input[data-v-73d555e6]{min-height:42px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
