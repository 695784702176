// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-row-footer[data-v-58489b60]{list-style:none;padding:0;margin-left:auto;display:flex;flex-direction:row}.tab-row-footer .resources-dropdown[data-v-58489b60],.tab-row-footer A[data-v-58489b60]{color:var(--link);display:flex;align-items:center;padding:10px 15px}.tab-row-footer .resources-dropdown.disabled[data-v-58489b60],.tab-row-footer A.disabled[data-v-58489b60]{cursor:not-allowed;pointer-events:none;filter:grayscale(1);color:var(--muted)}.resources-dropdown li[data-v-58489b60]{display:flex;justify-content:space-between;margin:10px 5px}.resources-dropdown .list-count[data-v-58489b60]{margin-left:30px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
