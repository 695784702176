// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box[data-v-1e60e558]{border:2px solid var(--tabbed-border);padding:10px;margin-bottom:20px;border-radius:var(--border-radius);flex-grow:1;flex-basis:0;position:relative}.info-box .stepped[data-v-1e60e558]{padding-left:40px}.info-box .step-number[data-v-1e60e558]{border-radius:var(--border-radius);background:var(--secondary);color:var(--input-text);display:inline-block;padding:5px 10px}.info-box .step-list[data-v-1e60e558]{margin:0}.info-box .info-column[data-v-1e60e558]:not(:last-child){border-right:1px solid var(--tabbed-border)}.info-box .info-row[data-v-1e60e558]{margin-bottom:10px}.info-box .info-row .info-row-label[data-v-1e60e558]{padding-top:2px;display:inline-block}.info-box .info-column label[data-v-1e60e558],.info-box .info-row label[data-v-1e60e558]{color:var(--input-label)}.info-box .info-column .usage[data-v-1e60e558]{margin-bottom:10px}.info-box .info-column .usage label[data-v-1e60e558]{display:inline-block;font-size:12px;padding-top:6px}.info-box .info-column .flex-item-half[data-v-1e60e558]{align-items:center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
