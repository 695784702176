// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zero circle{stroke:var(--gauge-zero)}.count-gauge{padding:10px;position:relative;display:flex;flex-direction:row;align-items:center}.count-gauge.clickable{cursor:pointer}.count-gauge .data{display:flex;flex-direction:column;flex:1}.count-gauge .data label{opacity:.7}.count-gauge .graphical{width:40px;height:40px;margin-right:10px}.count-gauge h1{font-size:40px;line-height:36px;padding-bottom:5px;margin-bottom:0}@media only screen and (min-width:768px){.count-gauge h1{font-size:40px;line-height:36px}}.count-gauge .alerts{position:absolute;right:10px;top:5px;font-size:15px}.count-gauge .alerts .text-error{margin-left:5px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
