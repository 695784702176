// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.has-link[data-v-aa9cb1bc]{cursor:pointer}.has-link[data-v-aa9cb1bc]:hover{border-color:var(--link)}[data-v-aa9cb1bc]  .content{display:flex;justify-content:space-between;align-items:center}[data-v-aa9cb1bc]  .content H1,[data-v-aa9cb1bc]  .content H3{margin:0}[data-v-aa9cb1bc]  .content .chip{border-radius:2em;color:var(--body-bg);padding:0 1em}[data-v-aa9cb1bc]  .content .chip.warn-count{background:var(--warning)}[data-v-aa9cb1bc]  .content .chip.error-count{background:var(--error)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
