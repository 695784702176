// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disable-auth-provider.card-container{box-shadow:none}.disable-auth-provider #confirm{width:90%;margin-left:3px}.disable-auth-provider .remove-modal{border-radius:var(--border-radius);overflow:scroll;max-height:100vh}.disable-auth-provider .remove-modal ::-webkit-scrollbar-corner{background:transparent}.disable-auth-provider .actions{text-align:right}.disable-auth-provider .card-actions{display:flex}.disable-auth-provider .card-actions .spacer{flex:1}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
