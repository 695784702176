// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rule-selector:not(.view) table[data-v-6768c971]{table-layout:auto}.rule-selector[data-v-6768c971]  .box{display:grid;grid-template-columns:25% 25% 25% 15%;-moz-column-gap:1.75%;column-gap:1.75%;align-items:center;margin-bottom:10px}.rule-selector[data-v-6768c971]  .box .key,.rule-selector[data-v-6768c971]  .box .operator,.rule-selector[data-v-6768c971]  .box .value{height:100%}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
