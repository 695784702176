// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header[data-v-af7b5e80]{display:flex}.header H1[data-v-af7b5e80]{flex:1}.empty-list[data-v-af7b5e80]{flex:1;justify-content:center;margin-top:6em;min-height:100%}.empty-list[data-v-af7b5e80],.empty-list .message[data-v-af7b5e80]{display:flex;align-items:center;flex-direction:column}.empty-list .message[data-v-af7b5e80]{margin-top:-5em}.empty-list .message .icon-10x[data-v-af7b5e80]{font-size:10em}.empty-list .message .icon-grey[data-v-af7b5e80]{color:var(--darker)}.empty-list .message .text-large[data-v-af7b5e80]{font-size:1.2em;padding:.5em}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
