// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-top-banner[data-v-7d2574dd]{top:calc(2em + var(--header-height))}.fixed-one-banner[data-v-7d2574dd]{height:calc(100vh - var(--header-height) - 2em)}.fixed-two-banners[data-v-7d2574dd]{height:calc(100vh - var(--header-height) - 4em)}.ember-page[data-v-7d2574dd]{display:flex;height:100%;padding:0}.frame[data-v-7d2574dd]{flex:1;visibility:hidden}.frame.pop[data-v-7d2574dd]{margin:-20px}.loading[data-v-7d2574dd]{visibility:visible}.ember-page-error[data-v-7d2574dd]{display:flex;align-items:center;flex:1;flex-direction:column;justify-content:center}.ember-page-error>div[data-v-7d2574dd]{font-size:20px;padding-bottom:20px}.inline-loading[data-v-7d2574dd]{border:1px solid var(--border);border-radius:5px;padding:10px;text-align:center;width:100%}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
