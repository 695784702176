// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fleet-status[data-v-18d9b1c6]{display:flex;width:100%;border:1px solid var(--border);border-radius:10px}.header[data-v-18d9b1c6]{display:flex;margin-bottom:15px;justify-content:space-between}.progress-container[data-v-18d9b1c6]{width:100%;padding:15px}.count[data-v-18d9b1c6]{padding:15px;background-color:var(--tabbed-container-bg);border-radius:10px 0 0 10px;display:flex;align-items:center;min-width:60px;justify-content:center;font-size:21px}.progress[data-v-18d9b1c6]{display:block;border-radius:90px;background-color:var(--progress-bg);height:10px;width:100%}.progress .piece[data-v-18d9b1c6]{display:inline-block;height:10px;border-radius:0;border-right:1px solid var(--progress-divider);vertical-align:top}.progress .piece[data-v-18d9b1c6]:first-child{border-top-left-radius:90px;border-bottom-left-radius:90px}.progress .piece[data-v-18d9b1c6]:last-child{border-top-right-radius:90px;border-bottom-right-radius:90px;border-right:0}.piece.bg-success[data-v-18d9b1c6]:only-child{opacity:.5}.meta-title[data-v-18d9b1c6]{display:flex;align-items:center}.meta-title[data-v-18d9b1c6]:hover{cursor:pointer;color:var(--link)}.meta-title .icon[data-v-18d9b1c6]{margin:4px 0 0 5px;opacity:.3}.resources-dropdown li[data-v-18d9b1c6]{display:flex;justify-content:space-between;margin:10px 5px}.resources-dropdown .list-count[data-v-18d9b1c6]{margin-left:30px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
