// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay{position:absolute;top:0;left:0;width:100vw;height:100vh;background-color:var(--overlay-bg);display:flex;justify-content:center;align-items:center;z-index:20}.modal-overlay .modal-container{background-color:var(--modal-bg);border-radius:var(--border-radius);max-height:95vh;overflow:auto;border:2px solid var(--modal-border)}.modal-fade-enter-active,.modal-fade-leave-active{transition:opacity .2s}.modal-fade-enter,.modal-fade-leave-to{opacity:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
