// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-state[data-v-8c367d9a]{align-items:center;display:inline-flex;padding:2px 10px;border:1px solid transparent;border-radius:20px}.badge-state.bg-info[data-v-8c367d9a]{border-color:var(--info)}.badge-state.bg-error[data-v-8c367d9a]{border-color:var(--error)}.badge-state.bg-warning[data-v-8c367d9a]{border-color:var(--warning)}.badge-state.bg-success[data-v-8c367d9a]{color:var(--success);background:transparent;border-color:var(--success)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
