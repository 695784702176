// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu[data-v-3f8c3e20],.root[data-v-3f8c3e20]{position:absolute}.menu[data-v-3f8c3e20]{visibility:hidden;top:0;left:0;z-index:41;min-width:145px;color:var(--dropdown-text);background-color:var(--dropdown-bg);border:1px solid var(--dropdown-border);border-radius:5px;box-shadow:0 5px 20px var(--shadow)}.menu LI[data-v-3f8c3e20]{align-items:center;display:flex;padding:8px 10px;margin:0}.menu LI[disabled][data-v-3f8c3e20]{cursor:not-allowed!important;color:var(--disabled-text)}.menu LI.divider[data-v-3f8c3e20]{padding:0;border-bottom:1px solid var(--dropdown-divider)}.menu LI[data-v-3f8c3e20]:not(.divider):hover{background-color:var(--dropdown-hover-bg);color:var(--dropdown-hover-text);cursor:pointer}.menu LI .icon[data-v-3f8c3e20]{display:unset;width:14px;text-align:center;margin-right:8px}.menu LI.no-actions[data-v-3f8c3e20],.menu LI.no-actions[data-v-3f8c3e20]:hover{color:var(--disabled-text)}.menu LI.no-actions[data-v-3f8c3e20]:hover{background-color:initial;cursor:default}.background[data-v-3f8c3e20]{position:fixed;top:0;left:0;right:0;bottom:0;opacity:0;z-index:40}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
