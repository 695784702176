// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/diff2html/bundles/css/diff2html.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-0ad03138]  .d2h-wrapper .d2h-file-header{display:none}[data-v-0ad03138]  .d2h-wrapper .d2h-file-wrapper{border-color:var(--diff-border)}[data-v-0ad03138]  .d2h-wrapper .d2h-diff-table{font-family:Menlo,Consolas,monospace;font-size:13px}[data-v-0ad03138]  .d2h-wrapper .d2h-code-side-emptyplaceholder,[data-v-0ad03138]  .d2h-wrapper .d2h-emptyplaceholder{border-color:var(--diff-linenum-border);background-color:var(--diff-empty-placeholder)}[data-v-0ad03138]  .d2h-wrapper .d2h-code-linenumber,[data-v-0ad03138]  .d2h-wrapper .d2h-code-side-linenumber{background-color:var(--diff-linenum-bg);color:var(--diff-linenum);border-color:var(--diff-linenum-border);border-left:0}[data-v-0ad03138]  .d2h-wrapper .d2h-code-line del,[data-v-0ad03138]  .d2h-wrapper .d2h-code-side-line del{background-color:var(--diff-line-del-bg)}[data-v-0ad03138]  .d2h-wrapper .d2h-code-line ins,[data-v-0ad03138]  .d2h-wrapper .d2h-code-side-line ins{background-color:var(--diff-line-ins-bg)}[data-v-0ad03138]  .d2h-wrapper .d2h-del{background-color:var(--diff-del-bg);border-color:var(--diff-del-border);color:var(--body-text)}[data-v-0ad03138]  .d2h-wrapper .d2h-ins{background-color:var(--diff-ins-bg);border-color:var(--diff-ins-border);color:var(--body-text)}[data-v-0ad03138]  .d2h-wrapper .d2h-info{background-color:var(--diff-header-bg);color:var(--diff-header);border-color:var(--diff-header-border)}[data-v-0ad03138]  .d2h-wrapper .d2h-file-diff .d2h-del.d2h-change{background-color:var(--diff-chg-del)}[data-v-0ad03138]  .d2h-wrapper .d2h-file-diff .d2h-ins.d2h-change{background-color:var(--diff-chg-ins)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
