// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-row{display:flex;align-items:center;margin-bottom:20px}.type-placeholder{color:#fff;font-size:2.5em;height:100%;width:100%;background-color:var(--primary);display:flex;justify-content:center;align-items:center}.type-description{color:var(--input-label)}.next-dropdown{display:inline-block}.tab-content-controls,.tab-external-controls{display:flex;justify-content:right}.tablist-controls .role-link{padding:10px 15px;min-height:unset;line-height:unset}.tablist-controls .role-link:focus{background:none;box-shadow:none}.tablist-controls .role-link:hover{border:none}.deployment-tabs>.tabs.horizontal{border-bottom:1px solid var(--border);margin-bottom:10px}.padded{padding-bottom:10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
