// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title[data-v-5479cad4]{margin-bottom:10px}.title .read-from-file[data-v-5479cad4]{float:right}.ports-headers[data-v-5479cad4],.ports-row[data-v-5479cad4]{display:grid;grid-column-gap:1.75%;margin-bottom:10px;align-items:center}.ports-headers.show-protocol[data-v-5479cad4],.ports-row.show-protocol[data-v-5479cad4]{grid-template-columns:23% 23% 10% 15% 15% 10%}.ports-headers.show-protocol[data-v-5479cad4]:not(.show-node-port),.ports-row.show-protocol[data-v-5479cad4]:not(.show-node-port){grid-template-columns:31% 31% 10% 15% 10%}.ports-headers.show-node-port[data-v-5479cad4]:not(.show-protocol),.ports-row.show-node-port[data-v-5479cad4]:not(.show-protocol){grid-template-columns:28% 28% 15% 15% 10%}.ports-headers[data-v-5479cad4]{color:var(--input-label)}.toggle-host-ports[data-v-5479cad4]{color:var(--primary)}.remove BUTTON[data-v-5479cad4]{padding:0}.ports-row>div[data-v-5479cad4]{height:100%}.ports-row .port-protocol[data-v-5479cad4]  .unlabeled-select .v-select.inline{margin-top:2px}.footer[data-v-5479cad4]{margin-top:10px;margin-left:5px}.footer .protip[data-v-5479cad4]{float:right;padding:5px 0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
