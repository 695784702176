// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password[data-v-547b0760]{display:flex;flex-direction:column}.change-password.change .form .fields[data-v-547b0760]{height:240px}.change-password.create[data-v-547b0760],.change-password.edit[data-v-547b0760]{height:185px}.change-password .form[data-v-547b0760],.change-password.create .form .fields[data-v-547b0760],.change-password.edit .form .fields[data-v-547b0760]{display:flex;flex-direction:column}.change-password .form .fields #password[data-v-547b0760],.change-password .form .fields #username[data-v-547b0760]{height:0;width:0;background-size:0;padding:0;border:none}.change-password .text-error[data-v-547b0760]{height:53px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
