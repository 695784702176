// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box[data-v-e0a42b90],.title[data-v-e0a42b90]{margin-bottom:10px}.box[data-v-e0a42b90]{display:grid;grid-template-columns:auto 75px;align-items:center}.box .value[data-v-e0a42b90]{flex:1}.box .value INPUT[data-v-e0a42b90]{height:40px}.remove[data-v-e0a42b90]{text-align:right}.footer .protip[data-v-e0a42b90]{float:right;padding:5px 0}.required[data-v-e0a42b90]{color:var(--error)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
