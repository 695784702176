// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.volume-source[data-v-84ebee2a]{padding:20px;margin:20px 0 20px 0;position:relative}.volume-source[data-v-84ebee2a]  .code-mirror .CodeMirror{background-color:var(--yaml-editor-bg)}.volume-source[data-v-84ebee2a]  .code-mirror .CodeMirror .CodeMirror-gutters{background-color:var(--yaml-editor-bg)}.remove-vol[data-v-84ebee2a]{position:absolute;top:10px;right:10px;padding:0}.add-vol[data-v-84ebee2a]:focus{outline:none;box-shadow:none}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
