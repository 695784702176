// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.operator .vs__dropdown-option[data-v-042c395e]{padding:3px 6px 3px 6px!important}.remove-expression[data-v-042c395e]{padding:8px;position:absolute;margin-bottom:10px;right:0;top:0;z-index:2}.remove-expression i[data-v-042c395e]{font-size:2em}.remove-container[data-v-042c395e]{display:flex;justify-content:center}.match-expression-header[data-v-042c395e],.match-expression-row[data-v-042c395e]{display:grid;grid-template-columns:1fr 1fr 1fr;margin:5px 0;grid-gap:1.75%}.match-expression-header>LABEL[data-v-042c395e],.match-expression-row>LABEL[data-v-042c395e]{margin:0}.match-expression-header[data-v-042c395e]:not(.view),.match-expression-row[data-v-042c395e]:not(.view){grid-template-columns:repeat(3,1fr) 50px}.match-expression-row>div>input[data-v-042c395e]{min-height:40px!important}.match-expression-header-matching[data-v-042c395e],.match-expression-row-matching[data-v-042c395e]{grid-template-columns:1fr 1fr 1fr 1fr}.match-expression-header-matching[data-v-042c395e]:not(.view),.match-expression-row-matching[data-v-042c395e]:not(.view){grid-template-columns:1fr 1fr 1fr 1fr 100px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
