// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:deep() P{font-size:medium;line-height:normal;font-weight:400;letter-spacing:normal;font-style:normal}.markdown blockquote{color:#656d76;border-left:.25em solid #d0d7de;padding:0 1em;margin-bottom:16px}.markdown table{border-collapse:collapse}.markdown TH{text-align:left;border:1px solid #e3e7eb}.markdown table tr th{font-weight:700;text-align:left;margin:0;padding:6px 13px}.markdown table tr td{text-align:left;margin:0;padding:6px 13px;border:1px solid #e3e7eb}.markdown table tr td :first-child,.markdown table tr th :first-child{margin-top:0}.markdown table tr td :last-child,.markdown table tr th :last-child{margin-bottom:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
