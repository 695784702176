// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xterm-char-measure-element{position:static}.container-shell .text-warning{animation:flasher 2.5s linear infinite}@keyframes flasher{50%{opacity:0}}.container-shell .shell-container{height:100%;overflow:hidden}.container-shell .shell-container .resize-observer{display:none}.container-shell .shell-body{padding:calc(var(--outline-width)*2);height:100%}.container-shell .containerPicker.unlabeled-select{display:inline-block;min-width:200px;height:30px;min-height:30px;width:auto}.container-shell .status{align-items:center;display:flex;min-width:80px;height:30px;margin-left:10px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
