// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grafana-graph[data-v-176c300a]{position:relative;min-height:100%;min-width:100%}.grafana-graph[data-v-176c300a]  .content{position:relative;display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;height:100%;padding:0}.grafana-graph[data-v-176c300a]  .overlay{position:static;background-color:initial}.grafana-graph iframe[data-v-176c300a]{position:absolute;left:0;right:0;top:20px;bottom:0;width:100%;height:100%;overflow:hidden}.grafana-graph iframe.loading[data-v-176c300a]{visibility:hidden}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
