// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-dropdown.btn-sm[data-v-4debc81a] >.vs__dropdown-toggle .vs__actions:after{font-size:1.6rem}.button-dropdown.btn-lg[data-v-4debc81a] >.vs__dropdown-toggle .vs__actions:after{font-size:2.6rem}.button-dropdown[data-v-4debc81a]{background:var(--accent-btn);border:solid 1px var(--link);color:var(--link);padding:0}.button-dropdown.vs--open[data-v-4debc81a] {outline:none;box-shadow:none}.button-dropdown[data-v-4debc81a]:hover  .vs__dropdown-toggle .vs__actions,.button-dropdown[data-v-4debc81a]:hover  .vs__selected-options{background:var(--accent-btn-hover)}.button-dropdown[data-v-4debc81a]:hover  .vs__selected-options .vs__selected button{background-color:transparent;color:var(--accent-btn-hover-text)}.button-dropdown[data-v-4debc81a]:hover  .vs__dropdown-toggle .vs__actions:after{color:var(--accent-btn-hover-text)}.button-dropdown[data-v-4debc81a] >.vs__dropdown-toggle{width:100%;display:grid;grid-template-columns:75% 25%;border:none;background:transparent}.button-dropdown[data-v-4debc81a] >.vs__dropdown-toggle .vs__actions:after{color:var(--link);line-height:1}.button-dropdown[data-v-4debc81a]  .vs__selected-options .vs__selected{margin:unset;border:none}.button-dropdown[data-v-4debc81a]  .vs__selected-options .vs__selected button{border:none;background:transparent;color:var(--link)}.button-dropdown[data-v-4debc81a]  .vs__selected-options .vs__search{position:absolute;opacity:0;padding:0}.button-dropdown[data-v-4debc81a]  .vs__dropdown-menu{min-width:unset;width:-moz-fit-content;width:fit-content}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
