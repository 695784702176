// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ember-iframe{border:0;left:calc(var(--nav-width) + 70px);height:calc(100vh - var(--header-height));position:absolute;top:var(--header-height);width:calc(100vw - var(--nav-width) - 70px);visibility:show}.ember-iframe-top-banner{top:calc(2em + var(--header-height))}.ember-iframe-one-banner{height:calc(100vh - var(--header-height) - 2em)}.ember-iframe-two-banners{height:calc(100vh - var(--header-height) - 4em)}.ember-iframe-inline{border:0;overflow:hidden}.ember-iframe-hidden{visibility:hidden}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
