// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-box{background:var(--simple-box-bg) 0 0 no-repeat padding-box;box-shadow:0 0 10px var(--simple-box-shadow);border:1px solid var(--simple-box-border);padding:15px}.simple-box .top{line-height:24px;font-size:18px;border-bottom:1px solid var(--simple-box-divider);padding-bottom:15px;margin:0 -15px 10px -15px;padding:0 15px 15px 15px;align-items:center;display:flex .simple-box .top BUTTON;display-padding:0;display-height:fit-content;display-align-self:flex-start}.simple-box .top H2{margin-bottom:0}.simple-box .content{padding:15px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
