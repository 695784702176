// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.window[data-v-688fc339]{height:100%}.window.show-grid[data-v-688fc339]{display:grid;grid-template-areas:"body" "title";grid-template-rows:auto 50px}.title[data-v-688fc339]{grid-area:title;background-color:var(--wm-title-bg);border-top:1px solid var(--wm-title-border);height:100%;vertical-align:middle;padding:10px;overflow-x:auto;overflow-y:hidden}.body[data-v-688fc339]{grid-area:body;height:100%;overflow:hidden}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
