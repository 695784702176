// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.with-copy[data-v-bae1bcd0]{border:solid 1px var(--border);padding:10px;position:relative;background-color:var(--input-bg);border-radius:var(--border-radius);border:solid var(--border-width) var(--input-border)}.with-copy>button[data-v-bae1bcd0]{position:absolute;top:-1px;right:-1px;border-radius:0 0 0 var(--border-radius)}.monospace[data-v-bae1bcd0]{white-space:pre-wrap;word-wrap:break-all}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
