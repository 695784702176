// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receiver .box-container{display:flex;justify-content:space-between;flex-wrap:wrap;margin:0 -10px}.receiver .box-container .subtype-banner{justify-content:space-between;align-items:center;flex-direction:row}.receiver .right{padding:30px;border-left:1px solid var(--border)}.receiver .logo{text-align:center;width:60px;height:60px;border-radius:calc(var(--border-radius)*2);overflow:hidden;background-color:#fff;display:inline-block;vertical-align:middle}.receiver .logo img{width:56px;height:56px;-o-object-fit:contain;object-fit:contain;position:relative;top:2px}.receiver .name{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin-bottom:0;display:inline-block;vertical-align:middle}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
