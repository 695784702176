// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.count[data-v-58b81ad0]{padding:10px;position:relative;display:flex;flex-direction:row;align-items:center;border-width:2px;border-style:solid;border-left:0}.count .data[data-v-58b81ad0]{display:flex;flex-direction:column;flex:1}.count .data label[data-v-58b81ad0]{opacity:.7}.count .data.compact[data-v-58b81ad0]{align-items:center;flex-direction:row}.count .data.compact h1[data-v-58b81ad0]{margin-bottom:0;padding-bottom:0}.count .data.compact label[data-v-58b81ad0]{margin-left:5px}.count h1[data-v-58b81ad0]{font-size:40px;line-height:36px;padding-bottom:5px;margin-bottom:5px}@media only screen and (min-width:768px){.count h1[data-v-58b81ad0]{font-size:40px;line-height:36px}}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
