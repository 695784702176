// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-readmes>h1[data-v-234a5cc0]{border-top:var(--header-border-size) solid var(--header-border)}.md[data-v-234a5cc0]{overflow:auto;max-width:100%;line-height:1.6}.md[data-v-234a5cc0]  *+H1,.md[data-v-234a5cc0]  *+H2,.md[data-v-234a5cc0]  *+H3,.md[data-v-234a5cc0]  *+H4,.md[data-v-234a5cc0]  *+H5,.md[data-v-234a5cc0]  *+H6{margin-top:20px}.md[data-v-234a5cc0]  code{font-size:13.5px;white-space:break-spaces;word-wrap:break-word;padding-left:5px;padding-right:5px;border:0}.md[data-v-234a5cc0]  pre{white-space:break-spaces;word-break:break-word}.md[data-v-234a5cc0] >h1:first-of-type{display:none}.md[data-v-234a5cc0]  p{margin-bottom:.5em}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
