// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title[data-v-37b2b4ba]{margin-bottom:10px}.title .read-from-file[data-v-37b2b4ba]{float:right}.ports-headers[data-v-37b2b4ba],.ports-row[data-v-37b2b4ba]{display:grid;grid-template-columns:28% 28% 15% 10% 75px .5fr;grid-column-gap:1.75%;margin-bottom:10px;align-items:center}.ports-headers .port[data-v-37b2b4ba],.ports-row .port[data-v-37b2b4ba]{display:flex;justify-content:space-between}.ports-headers.show-host[data-v-37b2b4ba],.ports-row.show-host[data-v-37b2b4ba]{grid-template-columns:20% 20% 145px 90px 140px .5fr .5fr}.ports-headers.show-ipam.loadBalancer.tcp[data-v-37b2b4ba],.ports-row.show-ipam.loadBalancer.tcp[data-v-37b2b4ba]{grid-template-columns:20% 20% 145px 90px .5fr 140px .5fr}.ports-headers.show-ipam.show-host.loadBalancer[data-v-37b2b4ba],.ports-row.show-ipam.show-host.loadBalancer[data-v-37b2b4ba]{grid-template-columns:20% 10% 135px 90px 105px .5fr .5fr .5fr}.ports-headers.show-ipam.show-host.loadBalancer.tcp[data-v-37b2b4ba],.ports-row.show-ipam.show-host.loadBalancer.tcp[data-v-37b2b4ba]{grid-template-columns:12% 10% 135px 90px 105px .5fr .5fr 100px .5fr}.add-host[data-v-37b2b4ba]{justify-self:center}.protocol[data-v-37b2b4ba]{height:100%}.ports-headers[data-v-37b2b4ba]{color:var(--input-label)}.toggle-host-ports[data-v-37b2b4ba]{color:var(--primary)}.remove BUTTON[data-v-37b2b4ba]{padding:0}.ports-row INPUT[data-v-37b2b4ba]{height:50px}.footer .protip[data-v-37b2b4ba]{float:right;padding:5px 0}.ports-row .protocol[data-v-37b2b4ba]  .unlabeled-select,.ports-row .protocol[data-v-37b2b4ba]  .unlabeled-select .v-select{height:100%}.ports-row .protocol[data-v-37b2b4ba]  .unlabeled-select .vs__dropdown-toggle{padding-top:12px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
