// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-panel[data-v-6ab6d576]{border:1px solid var(--border);border-radius:5px;padding:10px;position:relative;margin-top:10px}.group-panel .group-panel-title[data-v-6ab6d576]{position:absolute;top:-7px;background-color:var(--body-bg);padding:0 5px}.group-panel .group-panel-content[data-v-6ab6d576]{position:relative}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
