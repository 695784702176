// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container{border-radius:var(--border-radius);display:flex;flex-basis:40%;margin:10px;min-height:100px;padding:10px;box-shadow:0 0 20px var(--shadow)}.card-container.highlight-border{border-left:5px solid var(--primary)}.card-container:not(.top){align-items:top;flex-direction:row;justify-content:start}.card-container .card-wrap{width:100%}.card-container .card-body{color:var(--input-label);display:flex;flex-direction:column;justify-content:center}.card-container .card-actions{align-self:end;display:flex;padding-top:20px}.card-container .card-title{align-items:center;display:flex;width:100%}.card-container .card-title h5{margin:0}.card-container .card-title .flex-right{margin-left:auto}.card-container.card-sticky{overflow:hidden}.card-container.card-sticky .card-wrap{display:flex;flex-direction:column}.card-container.card-sticky .card-wrap .card-body{justify-content:flex-start;overflow:auto}.card-container.card-sticky .card-wrap>*{flex:0}.card-container.card-sticky .card-wrap .card-body{flex:1}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
