// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay[data-v-6e4d966c]{align-items:center;background-color:var(--overlay-bg);display:flex;justify-content:center;position:absolute;bottom:0;top:0;left:0;right:0;text-align:center;z-index:12}.overlay-main-mode[data-v-6e4d966c]{top:var(--header-height);z-index:51}.overlay-content-mode[data-v-6e4d966c]{left:calc(var(--nav-width));top:var(--header-height)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
