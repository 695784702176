// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-link[data-v-2504f242]{display:flex;align-items:center}.cluster-link .icon[data-v-2504f242]{visibility:hidden}.cluster-link .icon.navigating[data-v-2504f242]{visibility:visible}.no-clusters[data-v-2504f242]{text-align:center}.info-section[data-v-2504f242]{margin-top:60px}.logo[data-v-2504f242]{margin:60px 0 40px 0}.logo[data-v-2504f242],.tagline[data-v-2504f242]{display:flex;justify-content:center}.tagline[data-v-2504f242]{margin-top:30px}.tagline>div[data-v-2504f242]{font-size:16px;line-height:22px;max-width:80%;text-align:center}.link[data-v-2504f242]{cursor:pointer}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
