// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-loading-indicator[data-v-16594b2c]{border:1px solid var(--link);border-radius:10px;position:relative;width:-moz-min-content;width:min-content;overflow:hidden}.resource-loading-indicator .resource-loader[data-v-16594b2c]:last-child{position:absolute;top:0;background-color:var(--link);color:var(--link-text);overflow:hidden;white-space:nowrap}.resource-loading-indicator .resource-loader[data-v-16594b2c]{padding:1px 10px;width:-moz-max-content;width:max-content}.resource-loading-indicator .resource-loader .rl-bg[data-v-16594b2c],.resource-loading-indicator .resource-loader .rl-fg[data-v-16594b2c]{align-content:center;display:flex}.resource-loading-indicator .resource-loader .rl-bg>i[data-v-16594b2c],.resource-loading-indicator .resource-loader .rl-fg>i[data-v-16594b2c]{font-size:18px;line-height:18px}.resource-loading-indicator .resource-loader .rl-bg>span[data-v-16594b2c],.resource-loading-indicator .resource-loader .rl-fg>span[data-v-16594b2c]{margin-left:5px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
