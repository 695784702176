// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yaml-editor{display:flex;flex-direction:column}.yaml-editor .fill{flex:1}.yaml-editor .codemirror-container{position:relative}.yaml-editor .codemirror-container .CodeMirror,.yaml-editor .codemirror-container .CodeMirror .CodeMirror-gutters{background-color:var(--yaml-editor-bg)}.yaml-editor .diff-mode{background-color:var(--diff-header-bg);padding:5px 5px;border-bottom-right-radius:0;border-bottom-left-radius:0}.yaml-editor .d2h-file-wrapper{border-top-right-radius:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
