// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cluster-appearance[data-v-0c583d73]{display:flex;flex-direction:column;margin:3px 35px 0 0}.cluster-appearance label[data-v-0c583d73]{margin:6px 0 0}.cluster-appearance-preview[data-v-0c583d73]{display:flex;justify-content:center;align-self:start;gap:10px;justify-content:space-between}.cluster-appearance-preview button[data-v-0c583d73],.cluster-appearance-preview span[data-v-0c583d73]{display:flex;align-self:center;height:auto}.cluster-appearance-preview button[data-v-0c583d73]{margin:0;padding:0;top:0;color:var(--link);background:transparent}.cluster-appearance-preview button i[data-v-0c583d73]{margin-right:2px}.cluster-appearance-preview button[data-v-0c583d73]:disabled{color:var(--disabled-text);cursor:not-allowed}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
