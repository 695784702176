// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.principal[data-v-17adea52]{display:grid;grid-template-areas:"avatar name" "avatar description";grid-template-columns:40px auto;grid-template-rows:auto 20px;-moz-column-gap:10px;column-gap:10px}.principal.showLabels[data-v-17adea52]{grid-template-areas:"avatar name";grid-template-columns:60px auto;grid-template-rows:60px;-moz-column-gap:0;column-gap:0}.principal.showLabels .name[data-v-17adea52]{line-height:unset}.principal.showLabels table tr td[data-v-17adea52]:not(:first-of-type){padding-left:10px}.principal .avatar[data-v-17adea52]{grid-area:avatar;text-align:center}.principal .avatar DIV.empty[data-v-17adea52]{border:1px solid var(--border);line-height:40px}.principal .avatar IMG[data-v-17adea52]{width:40px;height:40px}.principal .avatar DIV.round[data-v-17adea52],.principal .avatar IMG.round[data-v-17adea52]{border-radius:50%}.principal .name[data-v-17adea52]{grid-area:name;line-height:20px;overflow-wrap:anywhere}.principal .description[data-v-17adea52]{grid-area:description;line-height:20px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
