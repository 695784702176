// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rancher-icon-fill[data-v-72250917]{fill:var(--primary)}.cluster-icon-menu[data-v-72250917]{position:relative;align-items:center;display:flex;height:32px;justify-content:center;width:42px}.cluster-pin-icon[data-v-72250917]{top:-6px;right:-7px;transform:scaleX(-1)}.cluster-pin-icon[data-v-72250917],.key-combo-icon[data-v-72250917]{position:absolute;font-size:14px;color:var(--body-text)}.key-combo-icon[data-v-72250917]{top:-7px;right:-8px;background-color:#dddee6;padding:2px;border-radius:2px}.cluster-local-logo[data-v-72250917]{width:20px}.cluster-badge-logo[data-v-72250917]{width:42px;height:32px;display:flex;align-items:center;justify-content:center;color:var(--default-active-text);font-weight:700;background:var(--nav-icon-badge-bg);border:1px solid var(--border);border-radius:5px;font-size:12px;text-transform:uppercase}.cluster-badge-logo .custom-color-decoration[data-v-72250917]{height:4px;width:100%;margin:0 auto;position:absolute;bottom:0;border-radius:0 0 5px 5px}.cluster-badge-logo.disabled[data-v-72250917]{color:var(--muted)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
