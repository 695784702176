// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader[data-v-f0123e3e]{position:relative;width:60px;height:60px}.loader--editable[data-v-f0123e3e]{cursor:pointer}.loader__image[data-v-f0123e3e]{width:100%;height:100%;border-radius:calc(var(--border-radius)*2);overflow:hidden;background-color:#fff}.loader__image img[data-v-f0123e3e]{-o-object-fit:contain;object-fit:contain}.loader__clear[data-v-f0123e3e]{display:none;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);z-index:1}.loader--editable:hover .loader__clear[data-v-f0123e3e]{display:inline-block}.loader--editable[data-v-f0123e3e]:hover:after{content:"";border-radius:calc(var(--border-radius)*2);position:absolute;top:0;left:0;width:100%;height:100%;background-color:var(--overlay-bg)}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
