// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-row[data-v-454ab0fd]{display:grid;grid-template-columns:45% 45% 10%;grid-column-gap:1.75%;margin-bottom:10px;align-items:center}.role-row .port[data-v-454ab0fd]{display:flex;justify-content:space-between}.role-row.show-host[data-v-454ab0fd]{grid-template-columns:30% 16% 10% 15% 15% 5%}.add-host[data-v-454ab0fd]{justify-self:center}.protocol[data-v-454ab0fd]{height:100%}.ports-headers[data-v-454ab0fd]{color:var(--input-label)}.toggle-host-ports[data-v-454ab0fd]{color:var(--primary)}.remove BUTTON[data-v-454ab0fd]{padding:0}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
